@import 'src/config/variables.scss';
@import 'src/config/mixins.scss';

.welcome-text {
  color: $color-text;
  font-weight: 700;
  margin-bottom: $main-spacing * 3;
  font-size: 2.2rem;
  &.light {
    color: $color-background-secondary;
    small {
      color: $color-background;
    }
  }
  &.big {
    font-size: 3.2rem;
    small {
      font-size: 1.5rem;
      padding-top: $main-spacing;
      font-weight: 400;
      margin-top: $main-spacing;
      opacity: 0.6;
    }
  }
  small {
    padding-top: $main-spacing * 0.5;
    font-size: 1.6rem;
    font-weight: 400;
    display: block;
  }
}

@media (min-width: map-get($breakpoints, md)) {
  .welcome-text {
    &.big {
      font-size: 4rem;
    }
  }
}
