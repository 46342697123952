@import 'src/config/variables';

.section-title {
  font-size: 1.8rem;
  font-weight: 700;
  text-transform: uppercase;
  margin: $main-spacing 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.highlight {
    font-size: 2.2rem;
    font-weight: 900;
  }

  &.no-margin-top {
    margin-top: 0;
  }
}
