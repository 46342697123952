@import '../../../config/variables.scss';

.my-superuser {
    position: relative;
    margin-top: $main-spacing * 2;
    .section-title {
        margin-bottom: $main-spacing * .5;
    }
    .content {
        padding-left: $main-spacing * 15;
        display: flex;
        flex-flow: column nowrap;
        height: 120px;
        justify-content: center;
    }
    .avatar {
        position: absolute;
        left: 0;
        top: 0;
        width: 120px;
        height: 120px;
        border-radius: 50%;
    }
    .contact-data {
        display: block;
        margin-bottom: $main-spacing;
        text-decoration: none;
    }
}