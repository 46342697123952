@import "src/config/variables.scss";
@import "src/config/mixins.scss";

.page-title {
  font-size: 2rem;
  color: $color-text;
  padding-bottom: $main-spacing * 2;
  font-weight: 600;

  @include gt-sm {
    font-size: 2.4rem;
  }

  @include gt-md {
    font-size: 2.8rem;
  }
}
