@import "../../../config/variables.scss";
@import "../../../config/mixins.scss";

.searchbar-wrapper {
  position: relative;
  min-width: 100%;
  background-color: $color-text;
  border: 1px solid $color-text;
  display: flex;
  align-items: stretch;
  overflow: hidden;
  margin-top: $main-spacing * 2;
  display: flex;

  .button-search {
    margin: $main-spacing / 5;
    padding-left: $main-spacing;
    padding-right: $main-spacing;

    @include gt-xs {
      padding-left: $main-spacing * 2;
      padding-right: $main-spacing * 2;
    }
  }
}

.searchbar-form {
  background: $color-background;
  width: 100%;
  @include gt-sm {
    width: 65%;
  }
}

.search-bar {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 0;
  align-items: flex-start;
  min-width: 100%;

  margin-left: -$main-spacing;
  margin-right: -$main-spacing;
  padding-left: $main-spacing;
  padding: $main-spacing;
  margin-bottom: $main-spacing;

  @include gt-sm {
    box-shadow: none;
  }
}

.search-icon-wrapper {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  background: transparent;
  color: #000;

  border-radius: 50%;
  color: $color-text;
}

.search-icon {
  font-size: 1.2rem;
  color: $color-text;
  margin-right: $main-spacing * 0.25;
  margin-left: $main-spacing * 0.25;
}

.search-query {
  width: 100%;
}

.search-query .search-input {
  padding: $main-spacing 1.5 * $main-spacing;
  background-position: right $main-spacing / 2 center;
  background-repeat: no-repeat;
  background-size: 10px;
  background-color: $color-background;
  border: transparent;
  width: 100%;

  @include gt-md {
    margin-left: $main-spacing;
  }
}

.search-field {
  margin-bottom: $main-spacing/2;
  width: 100%;
  select {
    height: 4 * $main-spacing;
    padding: 0 0.5 * $main-spacing auto;
    width: 100%;
  }
}

.search-fields {
  width: 100%;
  margin-bottom: $main-spacing / 2;
  align-content: space-between;

  &:not(.in-modal) {
    @include gt-sm {
      display: flex;

      .search-field {
        flex: 0 1 300px;
        margin-bottom: 0;
      }

      .search-query {
        margin-left: $main-spacing / 2;
        flex: 1 1 300px;
        display: flex;
      }
    }
  }
}

.search-header-cont {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: $main-spacing * 0.25;
  h4 {
    font-size: 1rem;
  }
}

@media (min-width: map-get($breakpoints, md)) {
  .search-bar {
    background: $color-background-secondary;
  }
  .search-fields {
    justify-content: start;
  }
}
