@import "../../../../config/variables.scss";

.external-link-icon {
  margin: 1 * $main-spacing 0;

  .icon-wrapper {
    .icon {
      svg {
        height: 30px;
        width: 30px;
      }
    }
  }
}

.external-link-icon .icon svg {
  fill: $color-text;
}
