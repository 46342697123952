@import './variables';

body {
  font-weight: 400;
  font-size: 1.4rem;
}

strong {
  font-weight: 600;
}

p {
  font-size: 1.5rem;
  line-height: 1.5;
  margin-bottom: $main-spacing;
  font-weight: 300;
}

ol,
ul.list {
  padding-left: $main-spacing * 2;

  li {
    font-size: 1.5rem;
    line-height: 1.5;
    margin-bottom: $main-spacing;
  }
}

ul.list {
  list-style-type: disc;
}

ol {
  ol {
    list-style-type: lower-latin;
  }
}

a {
  font-weight: 600;
  text-decoration: none;
}
