@import "./config/reset.scss";
@import "./config/variables.scss";
@import "./config/fonts.scss";
@import "./config/typography.scss";
.file-wrapper {
  border: 2px dashed $color-inactive;
  padding: $main-spacing * 4;
  text-align: center;
  cursor: pointer;
  transition: $transition-time;
  border-radius: $border-radius * 2;
  &:hover {
    background-color: darken($color-background-secondary, 10%);
  }

  input {
    display: none;
  }

  .img-preview {
    max-width: 300px;
    max-height: 300px;
  }
}

html {
  font-size: 62.5%;
  font-family: 'blocal', sans-serif;
}
