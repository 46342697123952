@import '../../../config/variables.scss';

.socials {
  display: flex;
  li {
    margin-right: 1.5 * $main-spacing;
  }
  .icon svg {
    width: 50px;
    height: 50px;
  }

  &.column {
    flex-direction: column;

    li {
      margin-right: 0;
      margin: $main-spacing 0;
    }
  }
}
